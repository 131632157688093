// import React, { useEffect, useRef, useState } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Button from "components/Button.jsx";
// import SuccessfulDialog from "components/Elements/SuccessfulDialog.jsx";
// import Svgs from "assets/svgs/Index.js";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import TimePicker from "react-time-picker"; // Import TimePicker

// // Form Validation Schema using Yup
// const meetingSchema = Yup.object().shape({
//   title: Yup.string().required("Title is required"),
//   date: Yup.date().required("Date is required"), // Use Yup.date() for date validation
//   time: Yup.string().required("Time is required"),
//   notes: Yup.string(),
// });

// function VerifiedSuccessful({ show, onClose }) {
//   const [showPopup, setShowPopup] = useState(false);
//   const popupRef = useRef();
//   const [time, setTime] = useState('10:00');

//   const handleClickOutside = (event) => {
//     if (popupRef.current && !popupRef.current.contains(event.target)) {
//       onClose();
//     }
//   };

//   const handleShowPopup = () => {
//     setShowPopup(true);
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false);
//     onClose();
//   };

//   useEffect(() => {
//     if (show || showPopup) {
//       document.body.style.overflow = "hidden";
//     } else {
//       document.body.style.overflow = "auto";
//     }

//     return () => {
//       document.body.style.overflow = "auto";
//     };
//   }, [show, showPopup]);

//   useEffect(() => {
//     if (show) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [show]);

//   if (!show) return null;

//   return (
//     <>
//       {!showPopup ? (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div ref={popupRef} className="flex items-center justify-center relative">
//             <span onClick={onClose} className="absolute -top-12 md:hidden">
//               <Svgs.CrossIconPopup />
//             </span>
//             <Formik
//               initialValues={{ title: "", date: "", time: "", notes: "" }}
//               validationSchema={meetingSchema}
//               onSubmit={(values, { setSubmitting }) => {
//                 setShowPopup(true);
//                 setSubmitting(false);
//               }}
//             >
//               {({ errors, touched, setFieldValue }) => (
//                 <Form>
//                   <div className="p-5 bg-white shadow-lg rounded-xl border w-[90vw] md:w-[52vw] mx-4">
//                     <div className="flex flex-col gap-3">
//                       <div className="flex items-center justify-center text-center">
//                         <h1 className="text-xl md:text-2xl font-semibold font-public-sans text-gray-700">
//                           Create a meeting
//                         </h1>
//                       </div>
//                       <div>
//                         <label className="text-base font-bold text-gray-700">Title</label>
//                         <Field
//                           name="title"
//                           type="text"
//                           className={`mt-1 block w-full border ${errors.title && touched.title ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  px-[16px] py-[10.5px]`}
//                           placeholder="Enter title"
//                         />
//                         <ErrorMessage name="title" component="div" className="text-red-500 text-sm mt-1" />
//                       </div>

//                       {/* Date Input Field */}
//                       <div className="grid sm:grid-cols-2 gap-3 grid-cols-1">
//                         <div className="w-full">
//                           <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">Date </label>
//                           <div className="relative">
//                             {/* DatePicker Input */}
//                             <DatePicker
//                               selected={undefined} // Control the selected date here
//                               onChange={(date) => setFieldValue('date', date)} // Use setFieldValue
//                               placeholderText="Select your date"
//                               className={`form-control lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[10.5px] border ${errors.date && touched.date ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm sm:text-sm pr-10`}
//                             />

//                             {/* Calendar Icon */}
//                             <div
//                               className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
//                               onClick={() => document.querySelector(".form-control").focus()}
//                             >
//                               <Svgs.CalendarIcon />
//                             </div>
//                           </div>
//                           <ErrorMessage name="date" component="div" className="text-red-500 text-sm mt-1" />
//                         </div>

//                         {/* Time Input Field */}
//                         {/* <div className="relative mt-[5px]">
//                           <label className="text-base font-bold text-gray-700">Select time</label>
//                           <Field
//                             name="time"
//                             type="time"
//                             className={`mt-1 block w-full border ${errors.time && touched.time ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-[16px] py-[10.5px]`}
//                           />
//                           <ErrorMessage name="time" component="div" className="text-red-500 text-sm mt-1" />
//                         </div> */}
//                       <div>
//       <TimePicker
//         onChange={setTime}
//         value={time}
//         disableClock={true}  // Optional, to hide the clock
//       />
//     </div>

//                       </div>

//                       <div>
//                         <label className="text-base font-bold text-gray-700">Meeting notes</label>
//                         <Field
//                           name="notes"
//                           as="textarea"
//                           className={`focus:ring-1 ring-black resize-none rounded-xl border w-full h-[130px] p-4 my-2 outline-none ${errors.notes && touched.notes ? 'border-red-500' : 'border-gray-300'}`}
//                           placeholder="Description"
//                         />
//                       </div>

//                       <div className="flex items-center justify-center">
//                         <button
//                           type="button"
//                           className="flex items-center justify-center w-[258px] h-[44px] gap-[13px] border border-[#FB6D72] rounded-xl text-[#F33F41] text-sm font-semibold"
//                         >
//                           <Svgs.AddIconRed />
//                           Add employees
//                         </button>
//                       </div>
//                       <div className="flex items-center justify-center mt-5">
//                         <Button
//                           handleClick={null}
//                           type="submit"
//                           text="Create meeting"
//                           customPadding="px-[24px] py-[14px]"
//                           className="h-[48px] w-[162px]"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </Form>
//               )}
//             </Formik>
//           </div>
//         </div>
//       ) : (
//         <SuccessfulDialog heading={'Meeting created successfully.'} show={showPopup} onClose={handleClosePopup} />
//       )}
//     </>
//   );
// }

// export default VerifiedSuccessful;
import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "components/Button.jsx";
import SuccessfulDialog from "components/Elements/SuccessfulDialog.jsx";
import Svgs from "assets/svgs/Index.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker"; // Import TimePicker
import "react-time-picker/dist/TimePicker.css"; // Import TimePicker CSS

// Form Validation Schema using Yup
const meetingSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  date: Yup.date().required("Date is required"), // Use Yup.date() for date validation
  time: Yup.string().required("Time is required"),
  notes: Yup.string(),
});

function VerifiedSuccessful({ show, onClose }) {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    onClose();
  };

  useEffect(() => {
    if (show || showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show, showPopup]);

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <>
      {!showPopup ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div ref={popupRef} className="flex items-center justify-center relative">
            <span onClick={onClose} className="absolute -top-12 md:hidden">
              <Svgs.CrossIconPopup />
            </span>
            <Formik
              initialValues={{ title: "", date: "", time: "10:00", notes: "" }}
              validationSchema={meetingSchema}
              onSubmit={(values, { setSubmitting }) => {
                setShowPopup(true);
                setSubmitting(false);
              }}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <div className="p-5 bg-white shadow-lg rounded-xl border w-[90vw] md:w-[52vw] mx-4">
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center justify-center text-center">
                        <h1 className="text-xl md:text-2xl font-semibold font-public-sans text-gray-700">
                          Create a meeting
                        </h1>
                      </div>
                      <div>
                        <label className="text-base font-bold text-gray-700">Title</label>
                        <Field
                          name="title"
                          type="text"
                          className={`mt-1 block w-full border ${
                            errors.title && touched.title ? "border-red-500" : "border-gray-300"
                          } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-[16px] py-[10.5px]`}
                          placeholder="Enter title"
                        />
                        <ErrorMessage name="title" component="div" className="text-red-500 text-sm mt-1" />
                      </div>

                      {/* Date Input Field */}
                      <div className="grid sm:grid-cols-2 gap-3 grid-cols-1">
                        <div className="w-full">
                          <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                            Date{" "}
                          </label>
                          <div className="relative">
                            {/* DatePicker Input */}
                            <DatePicker
                              selected={values.date}
                              onChange={(date) => setFieldValue("date", date)}
                              placeholderText="Select your date"
                              className={`form-control lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[10.5px] border ${
                                errors.date && touched.date ? "border-red-500" : "border-gray-300"
                              } rounded-lg shadow-sm sm:text-sm pr-10`}
                            />

                            {/* Calendar Icon */}
                            <div
                              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                              onClick={() => document.querySelector(".form-control").focus()}
                            >
                              <Svgs.CalendarIcon />
                            </div>
                          </div>
                          <ErrorMessage name="date" component="div" className="text-red-500 text-sm mt-1" />
                        </div>

                        {/* Time Input Field */}
                       
                           <div className="w-full">
                          <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                            Time{" "}
                          </label>
                          <div className="relative">
                            <TimePicker
                              onChange={(time) => setFieldValue("time", time)}
                              value={values.time}
                              disableClock={true}
                              className={`custom-time-picker form-control lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[10.5px] border ${
                                errors.time && touched.time ? "border-red-500" : "border-gray-300"
                              } rounded-lg shadow-sm sm:text-sm pr-10`}
                            />
                          </div>
                          <ErrorMessage name="time" component="div" className="text-red-500 text-sm mt-1" />
                        </div>  
                      </div>

                      <div>
                        <label className="text-base font-bold text-gray-700">Meeting notes</label>
                        <Field
                          name="notes"
                          as="textarea"
                          className={`focus:ring-1 ring-black resize-none rounded-xl border w-full h-[130px] p-4 my-2 outline-none ${
                            errors.notes && touched.notes ? "border-red-500" : "border-gray-300"
                          }`}
                          placeholder="Description"
                        />
                      </div>

                      <div className="flex items-center justify-center">
                        <button
                          type="button"
                          className="flex items-center justify-center w-[258px] h-[44px] gap-[13px] border border-[#FB6D72] rounded-xl text-[#F33F41] text-sm font-semibold"
                        >
                          <Svgs.AddIconRed />
                          Add employees
                        </button>
                      </div>
                      <div className="flex items-center justify-center mt-5">
                        <Button
                          handleClick={null}
                          type="submit"
                          text="Create meeting"
                          customPadding="px-[24px] py-[14px]"
                          className="h-[48px] w-[162px]"
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <SuccessfulDialog heading={"Meeting created successfully."} show={showPopup} onClose={handleClosePopup} />
      )}
    </>
  );
}

export default VerifiedSuccessful;

import React from "react";
import { useState, useEffect, useRef } from "react";




import DeleteDialog from "Pages/HRDashboard/Payroll/Elements/DeleteDialog";
import DeleteDialogChat from "Pages/UserDashboard/MyTasks/Element/DeleteDialogTask";

function MoreMeetingoptions({ show, onClose }) {
  const popupRef = useRef();


  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };
  const [showPopup, setShowPopup] = useState(false)

  const handleShowPopup = () => {
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    onClose()
  }

  const [showPopups, setShowPopups] = useState(false)

  const handleShowPopups = () => {
    setShowPopups(true)
  }

  const handleClosePopups = () => {
    setShowPopups(false)
    onClose()
  }



  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <>

      {!showPopup ?( <div ref={popupRef} className="w-[130px] flex items-start   flex-col  justify-center          bg-white shadow-lg border rounded-xl ">
          <div className="hover:bg-[#F2F2F2] hover:w-full py-2 px-3  hover:rounded-sm "> 
            <button onClick={handleShowPopups} className="cursor-pointer text-sm font-open-sans text-gray-1 ">Edit</button>
            {/* {showPopups && (< show={showPopups} onClose={handleClosePopups} />)} */}
          </div>
          <div  className="hover:bg-[#F2F2F2] hover:w-full py-2 px-3  hover:rounded-sm">
            <button onClick={handleShowPopup} className="cursor-pointer text-sm font-open-sans text-gray-1">Delete</button>
          
          </div>
        </div>
    

    ):(<DeleteDialogChat content={'Are you sure want to delete employee from payroll?'} show={showPopup} onClose={handleClosePopup} />)}
    </>
    )
}

export default MoreMeetingoptions;

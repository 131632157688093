import React from "react";
import Button from "../Button";
import Svgs from 'assets/svgs/Index';
import PopupModal from "components/common/popup-modal/popup-modal";

function SuccessChatModal({ deleteModal, setDeleteModal }) {

    return (
        <PopupModal
            isOpenModal={deleteModal}
            setIsOpenModal={setDeleteModal}
        >
            <div
                className="mx-2 p-5 scroll-smooth bg-white w-[90vw] md:w-[360px]">
                <div className="flex flex-col gap-3">
                    <div className="flex items-center justify-center text-center">
                        <span className="flex items-center justify-center text-center h-9 w-9 bg-[#60c26f] rounded-full">
                            <Svgs.TickIcon />
                        </span>
                    </div>
                    <div className="w-[60%] flex items-center justify-center text-center mx-auto">
                        <h1 className="text-base font-normal font-public-sans text-gray-1">
                            Removed as colleague
                        </h1>
                    </div>
                    <div className="flex items-center justify-center mt-5">
                        <Button
                            handleClick={() => setDeleteModal(false)}
                            text={"Close"}
                            customPadding={"px-[24px] py-[14px]"}
                            className={"h-[48px] w-[162px]"}
                        />
                    </div>
                </div>
            </div>
        </PopupModal>
    );
}

export default SuccessChatModal;

// import React from "react";
// import { useState, useEffect, useRef } from "react";
// import Addnewemployee from "../../Employees/Elements/Addnewemployee";
// import Addapplicant from "./Addsalary";
// import Addsalary from "./Addsalary";
// import DeleteDialog from "./DeleteDialog";
// import Edit from "./Editpayroll";
// import Editpayroll from "./Editpayroll";
// import EditGroceryDialog from "./EditGroceryDialog copy";
// import PopupModal from "components/common/popup-modal/popup-modal";
// import Button from "components/Button";

// function Moreoptions({ show, onClose, setPayrollPopups, setPayrollindex }) {
//   const popupRef = useRef();


//   const handleClickOutside = (event) => {
//     if (popupRef.current && !popupRef.current.contains(event.target)) {
//       onClose();
//     }
//   };
//   const [showpayrolldelte, setShowPayrolldelete] = useState(false)

//   const handleShowPayrolldelete = () => {
//     setShowPayrolldelete(true)
//   }

//   const handleClosePayrolldelete = () => {
//     setShowPayrolldelete(false)
//   }

//   const [showpayrolledit, setShowPayrolledit] = useState(false)

//   const handleShowPayrolledit = () => {
//     setShowPayrolledit(true)
//   }

//   const handleClosePayrolledit = () => {
//     setShowPayrolledit(false)
//   }



//   useEffect(() => {
//     if (show) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [show]);

//   if (!show) return null;

//   return (
//     <div className=" inset-0 flex items-center justify-center bg-white relative z-50 ">
//       <div ref={popupRef} className="absolute   right-2  mt-16    scroll-smooth  bg-white shadow-lg border rounded-xl overflow-y-auto  ">
//         <div className="w-[130px] flex items-start   flex-col h-[80px] ">
//           <div><button onClick={handleShowPayrolledit} className="cursor-pointer text-sm font-open-sans text-gray-1 py-2 px-3">Edit</button>
//             {showpayrolledit && (<EditGroceryDialog show={showpayrolledit} onClose={handleClosePayrolledit} />)}
//           </div>
//           <div>
//             <button onClick={handleShowPayrolldelete} className="cursor-pointer text-sm font-open-sans text-gray-1 py-2 px-3">Delete</button>
//             {showpayrolldelte && (<DeleteDialog content={'Are you sure want to delete employee from payroll?'} show={showpayrolldelte} onClose={handleClosePayrolldelete} />)}
//           </div>

//           {/* <PopupModal buttonTitle={'Delete'} isOpenModal={showpayrolldelte} setIsOpenModal={setShowPayrolldelete} setPayrollPopups={setPayrollPopups} setPayrollindex={setPayrollindex} >
//             <div className=" flex items-center justify-center bg-black bg-opacity-50 z-50">
//               <div ref={popupRef}>

//                 <div className="bg-white">
//                   <div className="flex flex-col gap-3 py-[36px]">
//                     <div className=" w-[60%] flex items-center justify-center text-center mx-auto">
//                       <h1 className=" text-base font-normal font-public-sans text-gray-1">
//                         {'Are you sure want to delete employee from payroll?'}
//                       </h1>
//                     </div>
//                     <div className=" flex items-center justify-center gap-5 mt-5">
//                       <Button
//                         // handleClick={onClose}
//                         text={'No'}
//                         customPadding={'px-[24px] py-[14px]'}
//                         className={'h-[48px] w-[104px]'} />
//                       <button
//                         onClick={() => setShowPayrolldelete(false)}
//                         // onClick={handleShowPopup}
//                         className=" text-base font-bold font-mulish text-gray-1 px-[24px] py-[14px]">
//                         Delete
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//               </div>
//             </div>
//           </PopupModal> */}
//         </div>
//       </div>

//     </div>

//   );
// }

// export default Moreoptions;
import React, { useEffect, useRef, useState } from 'react';
import DeleteDialog from './DeleteDialog';
import EditGroceryDialog from '../../Payroll/Elements/EditGroceryDialog copy';

function Moreoptions({ show, onClose, setPayrollPopups, setPayrollindex }) {
  const popupRef = useRef();
  const [showpayrolldelte, setShowPayrolldelete] = useState(false);
  const [showpayrolledit, setShowPayrolledit] = useState(false);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  const handleCloseBoth = () => {
    setPayrollPopups(false);
    setPayrollindex(null); // Reset index after closing
  };

  if (!show) return null;

  return (
    <div className="inset-0 flex items-center justify-center bg-white relative z-50">
      <div ref={popupRef} className="absolute right-2 mt-16 bg-white shadow-lg border rounded-xl overflow-y-auto">
        <div className="w-[130px] flex items-start flex-col h-[80px]">
          <button
            onClick={() => setShowPayrolledit(true)}
            className="w-full py-2 px-4 hover:bg-gray-100 text-sm text-left"
          >
            Edit
          </button>
          <button
            onClick={() => setShowPayrolldelete(true)}
            className="w-full py-2 px-4 hover:bg-gray-100 text-sm text-left"
          >
            Delete
          </button>
        </div>
      </div>

      {showpayrolldelte && (
        <DeleteDialog content={'Are you sure want to delete employee from payroll?'} 
          show={showpayrolldelte}
          onClose={() => {
            setShowPayrolldelete(false);
            handleCloseBoth(); // Close after deleting
          }}
        />
      )}
      {showpayrolledit && (
        <EditGroceryDialog
          show={showpayrolledit}
          onClose={() => {
            setShowPayrolledit(false);
            handleCloseBoth(); // Close after editing
          }}
        />
      )}
    </div>
  );
}

export default Moreoptions;

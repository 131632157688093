export  const PayrollData = [
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "Python developer",
      rate: "Fix Amount",
      date: "3/08/2024",

      statusColor: "bg-[#faf2e4] text-[#F4A012]",
      positionColor: "bg-[#e9eefb] text-[#497CFF]",
      amount_rate: "$1200",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "Web developer",
      rate: "Fix Amount",
      date: "3/08/2024",

      statusColor: "bg-[#e2f3e7] text-[#00B037]",
      positionColor: "bg-[#faf2e4] text-[#F4A012]",
      amount_rate: "$1000",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "Python developer",
      rate: "Fix Amount",
      date: "3/08/2024",
      statusColor: "bg-[#faf2e4] text-[#F4A012]",
      positionColor: "bg-[#e9eefb] text-[#497CFF]",
      amount_rate: "$1200",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "UI/UX developer",
      rate: "HOURLY",
      date: "3/08/2024",

      statusColor: "bg-[#faeaea] text-[#F54D4D]",
      positionColor: "bg-[#e2f3e7] text-[#00B037]",
      amount_rate: "$25",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "Python developer",
      rate: "Fix Amount",
      date: "3/08/2024",

      statusColor: "bg-[#faf2e4] text-[#F4A012]",
      positionColor: "bg-[#e9eefb] text-[#497CFF]",
      amount_rate: "$900",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "QA Tester",
      rate: "Fix Amount",
      date: "3/08/2024",

      statusColor: "bg-[#e2f3e7] text-[#00B037]",
      positionColor: "bg-[#faf2e4] text-[#F4A012]",
      amount_rate: "$800",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "UI/UX developer",
      rate: "HOURLY",
      date: "3/08/2024",

      statusColor: "bg-[#faf2e4] text-[#F4A012]",
      positionColor: "bg-[#e2f3e7] text-[#00B037]",
      amount_rate: "$25",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },

    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "Digital marketing",
      rate: "Fix Amount",
      date: "3/08/2024",
      period_status: "Pending",
      statusColor: "bg-[#faeaea] text-[#F54D4D]",
      positionColor: "bg-[#e9eefb] text-[#497CFF]",
      amount_rate: "$900",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "QA Tester",
      rate: "Fix Amount",
      date: "3/08/2024",
      statusColor: "bg-[#e2f3e7] text-[#00B037]",
      positionColor: "bg-[#faf2e4] text-[#F4A012]",
      amount_rate: "$800",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "QA Tester",
      rate: "Fix Amount",
      date: "3/08/2024",
      statusColor: "bg-[#e2f3e7] text-[#00B037]",
      positionColor: "bg-[#faf2e4] text-[#F4A012]",
      amount_rate: "$800",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "QA Tester",
      rate: "Fix Amount",
      date: "3/08/2024",
      statusColor: "bg-[#e2f3e7] text-[#00B037]",
      positionColor: "bg-[#faf2e4] text-[#F4A012]",
      amount_rate: "$800",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
    {
      name: "Catherine",
      email: "Catherine14@gmail.com",
      position: "QA Tester",
      rate: "Fix Amount",
      date: "3/08/2024",
      statusColor: "bg-[#e2f3e7] text-[#00B037]",
      positionColor: "bg-[#faf2e4] text-[#F4A012]",
      amount_rate: "$800",
      period_date: "3/08/24 to 2/09/24",
      period: "MONTHLY",
    },
  ];
import React from "react";
import { useState, useEffect, useRef } from "react";

import DeleteDialogChat from "Pages/UserDashboard/MyTasks/Element/DeleteDialogTask";
import DeleteDialogApplicants from "./DeleteDialogDots";

function MoreApplicantsoptions({ show, onClose }) {
  const popupRef = useRef();


  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };
  const [showPopup, setShowPopup] = useState(false)

  const handleShowPopup = () => {
    setShowPopup(true)
    setShowPopups(false)
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setShowPopups(false)
  }

  const [showPopups, setShowPopups] = useState(false)


  // setShowPopups

  const handleShowPopups = () => {
    setShowPopups(true)
  }

  const handleClosePopups = () => {
    setShowPopups(false)
  }



  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <>
    {!showPopup ?(<div className=" inset-0 flex items-center justify-center bg-white relative z-50 ">
      <div ref={popupRef} className="absolute   right-2  mt-16    scroll-smooth  bg-white shadow-lg border rounded-xl overflow-y-auto  ">
        <div className="w-[130px] flex items-start   flex-col h-[80px] ">
          <div onClick={handleShowPopups} className="hover:bg-[#F2F2F2] py-2 px-3 w-full">
            <button  className="cursor-pointer text-sm font-open-sans text-gray-1  text-start flex justify-start items-start">Edit</button>
            {/* {showPopups && (<EditGroceryDialog show={showPopups} onClose={handleClosePopups} />)} */}
          </div>
          <div onClick={handleShowPopup} className="hover:bg-[#F2F2F2]  py-3 px-3 w-full">
            <button  className="cursor-pointer text-sm font-open-sans text-gray-1  text-start flex justify-start items-start">Delete</button>
           
          </div>
        </div>
      </div>

    </div>):(<DeleteDialogApplicants content={'Are you sure want to delete applicant?'} show={showPopup} onClose={handleClosePopup} />)}
    </>
  );
}

export default MoreApplicantsoptions;
import React from "react";
import Button from "../../../../components/Button";
import PopupModal from "components/common/popup-modal/popup-modal";

function DeleteDialog({ removeModal, setRemoveModal, setDeleteModal }) {
  return (
    <>
      <PopupModal isOpenModal={removeModal} setIsOpenModal={setRemoveModal}>
        <div className=" mx-auto scroll-smooth bg-white shadow-lg rounded-xl border w-[360px]">
          <div className="flex flex-col gap-3 my-[36px]">
            <div className=" w-[60%] flex items-center justify-center text-center mx-auto">
              <h1 className=" text-base font-normal font-public-sans text-gray-1">
                Are you sure want to remove Orton as colleage
              </h1>
            </div>
            <div className=" flex items-center justify-center gap-5 mt-5">
              <Button
                text={"No"}
                customPadding={"px-[24px] py-[14px]"}
                className={"h-[48px] w-[104px]"}
              />

              <button
                onClick={() => {
                  setDeleteModal(true);
                  setRemoveModal(false);
                }}
                className=" text-base font-bold font-mulish text-gray-1 px-[24px] py-[14px]"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </PopupModal>
    </>
  );
}

export default DeleteDialog;

import React, { useState } from "react";
import SuccessfulDialog from "components/Elements/SuccessfulDialog";
import { Input } from "components/Input";
import SelectDropdown from "components/SelectDropdown";

function Addsalary({ onClose }) {
  const [formData, setFormData] = useState({
    fullName: "",
    Contactnumber: "",
    emailid: "",
    salary: "",
    expectaion: "",
    experience: "",
    noticeperiod: "",
    identityproof: "",
    uniqueid: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [showPopup, setShowsPopup] = useState(false);

  const handleShowPopup = () => {
    setShowsPopup(true);
  };

  const handleClosePopup = () => {
    setShowsPopup(false);
    onClose();
  };

  const roleOptions = [
    { value: "designation", label: "Designation" },
    { value: "Web developer", label: "Web developer" },
    { value: "python developer", label: "Python developer" },
    { value: "ui ux designer", label: "UI UX designer" },
    { value: "digital marketing", label: "Digital marketing" },
  ];

  const payOptions = [{ value: "fix", label: "Fix" }];
  return (
    <>
      {!showPopup ? (
        <div className="flex justify-center ">
          <div className="bg-white  rounded-[16px]  w-full py-4 ">
            <h2 className="lg:text-[24px] text-[18px]  font-semibold  text-center text-gray-1 font-public-sans">
              Add salary
            </h2>

            <div>
              <form>
                <div className="mt-[32px] px-6">
                  <div className="mb-4  ">
                    <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                      Select employee
                    </label>
                    <div className="  rounded-lg shadow-sm mt-[6px]    justify-between  ">
                      <SelectDropdown
                        label=""
                        options={roleOptions}
                        name="role"
                        id="role"
                        selectClass={"w-full"}
                        className={"w-full"}
                        customPadding={"px-4 py-[4.5px] "}
                        customargin={"mt-3"}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-[32px] px-6">
                  <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                    Employee position
                  </label>
                  <div className="    shadow-sm mt-[6px]    justify-between">
                    <SelectDropdown
                      label=""
                      options={roleOptions}
                      name="role"
                      id="role"
                      selectClass={"w-full"}
                      className={"w-full"}
                      customPadding={"px-4 py-[4.5px] "}
                      customargin={"mt-3"}
                    />
                  </div>
                </div>

                <div className="mt-[24px] px-6 ">
                  <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                    Designation
                  </label>
                  <div className="    shadow-sm mt-[6px]    justify-between">
                    <SelectDropdown
                      label=""
                      options={roleOptions}
                      name="role"
                      id="role"
                      selectClass={"w-full"}
                      className={"w-full "}
                      customPadding={"px-4 py-[4.5px] "}
                      customargin={"mt-3"}
                    />
                  </div>
                  {/* <div className='absolute   right-4 top-12 '>< Calenderhr/></div> */}
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[24px] px-6">
                  <div>
                    <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">
                      Pay type
                    </label>
                    <div className="     justify-between">
                      <SelectDropdown
                        label=""
                        options={payOptions}
                        name="role"
                        id="role"
                        selectClass={"w-full"}
                        className={"w-full"}
                        customPadding={"px-4 py-[2.5px] "}
                        customargin={"mt-3"}
                      />
                    </div>
                  </div>
                  <div className="">
                    <Input
                      label={"Salary"}
                      type={"text"}
                      placeholder={"$000000"}
                      className={
                        " text-gray-1 lg:text-base font-mulish   text-sm"
                      }
                    />
                  </div>
                </div>

                <div className="my-[24px] px-6 ">
                  <Input
                    label={"Employee email"}
                    type={"text"}
                    placeholder={"User@email.com"}
                    className={
                      "placeholder-gray-4 lg:text-base font-mulish   text-sm"
                    }
                  />
                </div>
              </form>
              <div className="flex justify-center my-[32px]">
                <button
                  onClick={handleShowPopup}
                  className="lg:text-base w-[162px] text-sm font-mulish font-bold 
         py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
         shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  {"Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SuccessfulDialog
          heading={"Salary added successfully"}
          show={showPopup}
          onClose={handleClosePopup}
        />
      )}
    </>
  );
}

export default Addsalary;

import React, { useState, useEffect, useRef } from "react";
import { Input } from "components/Input";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import Svgs from 'assets/svgs/Index';
import SelectDropdown from "components/SelectDropdown.jsx";
import SuccessfulDialog from "components/Elements/SuccessfulDialog.jsx";

function EditGroceryDialog({ show, onClose }) {
  const [isVisible, setIsVisible] = useState(true);
  const [isVisible1, setIsVisible1] = useState(true);
  const navigate = useNavigate();
  const [showSuccessfulPopup, setShowSuccessfulPopup] = useState(false);
  const popupRef = useRef();

  const handleRemove = () => setIsVisible(false);
  const handleRemove1 = () => setIsVisible1(false);

  const handleShowSuccessfulPopup = () => {
    setShowSuccessfulPopup(true);
    onClose(); // Close the popup when the update is successful
  };

  const handleCloseSuccessfulPopup = () => {
    setShowSuccessfulPopup(false);
    onClose(); // Ensure the background popup also closes
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (show || showSuccessfulPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show, showSuccessfulPopup]);

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  if (!show) return null;

  return (
    <>
      {!showSuccessfulPopup ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div ref={popupRef} className="grocery-scrollbar-none bg-white w-full md:w-[848px] rounded-xl p-5 overflow-auto h-[80vh]">
            <div className="flex items-center justify-center">
              <h1 className="text-lg md:text-2xl font-semibold font-public-sans text-gray-1">Edit Grocery dashboard</h1>
            </div>

            <div className="my-5 mt-[48px]">
              <Input label={"Task name"} placeholder={'Task name'} />
              <h1 className="text-base font-bold font-mulish text-gray-1">Description</h1>
              <div className="border rounded-xl p-5 cursor-text">
                <p className="text-xs md:text-base font-normal font-public-sans text-gray-4 cursor-text ">
                  The dashboard of the MR Corporation Grocery Mobile App is crafted to deliver a seamless and intuitive user experience...
                </p>
                <ul className="list-disc px-4 my-4">
                  <li className="text-xs md:text-base font-normal font-public-sans text-gray-4">Clean Layout...</li>
                  <li className="text-xs md:text-base font-normal font-public-sans text-gray-4">Intuitive Navigation...</li>
                </ul>
              </div>
            </div>

            <div>
              <h1 className="text-sm font-normal font-public-sans text-gray-1">Attachments</h1>
              <div className="my-4">
                <div className="flex items-center flex-wrap justify-center md:justify-between gap-4 my-[16px]">
                  {isVisible && (
                    <button className="relative border rounded-xl px-[14px] py-[10px] bg-[#f3f3f3] flex items-center justify-between w-full sm:w-[273px]">
                      <span className="text-sm font-semibold text-gray-2 font-public-sans">Detailed document</span>
                      <span className="rounded-full bg-white"><Svgs.Oction_download /></span>
                      <span onClick={handleRemove} className="absolute -top-2 -right-2"><Svgs.CrossIconRed /></span>
                    </button>
                  )}
                  {isVisible1 && (
                    <button className="relative border rounded-xl px-[14px] py-[10px] bg-[#f3f3f3] flex items-center justify-between w-full sm:w-[273px]">
                      <span className="text-sm font-semibold text-gray-2 font-public-sans">Detailed document</span>
                      <span className="rounded-full bg-white"><Svgs.Oction_download /></span>
                      <span onClick={handleRemove1} className="absolute -top-2 -right-2"><Svgs.CrossIconRed /></span>
                    </button>
                  )}
                  <button className="border border-[#f7585c] rounded-xl px-[12px] py-[11.5px] flex items-center gap-4">
                    <span><Svgs.DocumentRedIcon /></span>
                    <h1 className="text-[#f7585c] text-base font-medium font-public-sans">Add attachments</h1>
                  </button>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between my-6">
              <div className="flex flex-col gap-2">
                <h1 className="text-sm font-normal font-public-sans text-gray-2">Members</h1>
                <img onClick={() => { navigate('/user/myteamlast'); }} src="/images/Member4.png" alt="" className="w-[42px] md:w-fit" />
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="text-sm font-normal font-public-sans text-gray-2">Priority</h1>
                <SelectDropdown options={[{ value: 'Easy', label: 'Easy' }, { value: 'Medium', label: 'Medium' }, { value: 'High', label: 'High' }]} className={'w-[105px] '} />
              </div>
            </div>

            <div className="flex items-center justify-center mt-[48px]">
              <Button handleClick={handleShowSuccessfulPopup} text={'Update'} className={'w-[162px] h-[48px]'} customPadding={'px-[24px] py-[14px]'} />
            </div>
          </div>
        </div>
      ) : (
        <SuccessfulDialog heading={'Update Successful'} show={showSuccessfulPopup} onClose={handleCloseSuccessfulPopup} />
      )}
    </>
  );
}

export default EditGroceryDialog;


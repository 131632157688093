import React, { useRef } from "react";

const SalaryModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      {/* Overlay */}
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50" />

      {/* Modal */}
      <div
        ref={modalRef}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg w-[764px] h-[90%]  max-h-[1080px] py-[50px] px-[48px] z-50 p-6 font-poppins overflow-y-scroll"
      >
        {/* Background Logo */}
        {/* <div
          className="absolute top-[510.63px] left-[38px] w-[1217.17px] h-[936.14px] opacity-[0.12] -z-10 bg-cover bg-center rotate-[31.7deg]">
          <img src="/images/non-disclosure-agreement-uplead 1.png" alt="" />
          </div> */}
          <div className="bg-cover  absolute right-0">
            <img src="/images/flower.png" alt="" />
          </div>

        {/* Logo and Title */}
        <div className="text-center mb-[50px]">
         
          <img className="mx-auto mb-4" src="/images/mrlogo.png" alt="" />
          <h2 className="font-semibold text-[20px] font-poppins leading-[30px] border-b-2 border-[#000000] inline-block text-[#000000] ">
            Salary Slip
          </h2>
        </div>

        {/* Billed To Section */}
        <div className="mb-[90px]">
          <p className="text-[16px] leading-[24px] font-poppins mb-[10px] font-semibold  text-[#000000] ">
            Billed to:
          </p>
          <p className="text-[14px] leading-[24px] font-poppins font-medium">Name: Employee Name</p>
          <p className="text-[14px] leading-[24px] font-poppins font-medium">Address: 123 Anywhere St., Any City, ST 12345</p>
          <p className="text-[14px] leading-[24px] font-poppins font-medium">Phone: +123-456-7890</p>
          <p className="text-[14px] leading-[24px] font-poppins font-medium">Salary month - august</p>
        </div>

        {/* Salary Details Section */}
        <div className="w-[668px] mx-auto border border-black">
          {/* Header Row */}
          <div className="flex justify-between py-[8px] px-[16px]  bg-black text-white">
            <span className="font-semibold text-[16px] font-poppins leading-[24px] ">Salary</span>
            <span className="font-semibold text-[16px] font-poppins leading-[24px]">Amount</span>
          </div>

          {/* Salary Details */}
          <div className="">
            <div className="flex justify-between items-center  mt-[36px] px-[16px]  ">
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000]"> Salary</span>
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000] font-semibold">$600</span>
            </div>
            <p className=" border-t-2 mt-[8px] border-dashed   border-[#000000]"></p>

            <div className="flex justify-between items-center mt-[28px] px-[16px] ">
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000] font-semibold">Deductions</span>
             </div>

            <div className="flex justify-between items-center mt-[24px] px-[16px] ">
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000]">Medical Leave</span>
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000] font-semibold">$0</span>
            </div>
            <div className="flex justify-between items-center  mt-[8px] px-[16px]">
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000]">Casual Leave</span>
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000] font-semibold">$0</span>
            </div>
            <p className="border-t pt-2 border-[#000000] mt-[8px]"></p>
            <div className="flex justify-between items-center font-semibold  py-[8px] px-[16px]">
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000]">Total Salary</span>
              <span className="text-[16px] font-poppins leading-[24px] text-[#000000] font-semibold">$600</span>
            </div>
          </div>
          </div>

          <div className="mt-[41px] ">
            <span className=" "><img src="/images/sign.png" alt="" /></span>
            </div>

            <div className="relative">
            <h2 className="text-[16px] font-poppins leading-[24px] text-[#000000] absolute -top-11">Authorised signature</h2>
            </div>
      </div>
    </>
  );
};

export default SalaryModal;

import React, { useEffect, useState } from 'react'
import { Crossiconssvg, Fileattachsvg } from 'assets/svgs/Index';
import SuccessfulDialog from 'components/Elements/SuccessfulDialog';
import SelectDropdown from 'components/SelectDropdown';
import svgs from '../../../../assets/svgs/Index'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"



function Addnewemployee({ onClose }) {

  const [formData, setFormData] = useState({
    fullName: '',
    department: '',
    position: '',
    dateOfJoining: '',
    phoneNumber: '',
    emailId: '',
    identityProof: '',
    uniqueIdNumber: '',
    dateOfBirth: '',
    maritalStatus: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const headings = ["Basic info", "Personal info", "Documents", "Bank details", "Skills"];


  const [activeStep, setActiveStep] = useState(0);

  const steps = ["Basic info", "Personal info", "Documents", "Bank details", "Skills"];

  const handleNextClick = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const prevsStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }

  };
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleFileChange = (e, label) => {
    const file = e.target.files[0];
    if (file) {
      console.log(`${label} uploaded:`, file.name);
    }
  }
  // date inputr uderef


  const [startDates, setStartDates] = useState(null);





  const FileUpload = ({ label }) => {
    return (
      <div className='flex flex-col '>
        <div>
          <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish ">{label}</label>
        </div>
        <div className="flex px-[16px] py-[12.5px] border border-gray-500 rounded-lg shadow-sm   mt-[8px] ">
          <input
            type="file"
            onChange={(e) => handleFileChange(e, label)}
            className="hidden"
            id={label.replace(/\s+/g, '-').toLowerCase()}
          />
          <label
            htmlFor={label.replace(/\s+/g, '-').toLowerCase()}
            className="flex-grow cursor-pointer text-gray-500"
          >
            Upload
          </label>
          <label
            htmlFor={label.replace(/\s+/g, '-').toLowerCase()}
            className="cursor-pointer text-red-500"
          >
            <Fileattachsvg />
          </label>
        </div>
      </div>
    );
  };

  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState("");

  // Function to add skills, supports adding multiple skills separated by commas or spaces
  const handleAddSkill = () => {
    const newSkills = skill
      .split(/[\s,]+/) // Split by space or comma
      .map(s => s.trim()) // Trim white spaces
      .filter(s => s && !skills.includes(s)); // Remove duplicates and empty entries

    if (newSkills.length) {
      setSkills([...skills, ...newSkills]); // Add new skills to state
      setSkill(""); // Clear the input
    }
  };

  // Function to remove a specific skill
  const handleRemoveSkill = (removedSkill) => {
    setSkills(skills.filter((s) => s !== removedSkill)); // Remove the clicked skill
  };
  const [showPopup, setShowsPopup] = useState(false);

  const handleShowPopup = () => {
    setShowsPopup(true);
  };

  const handleClosePopup = () => {
    setShowsPopup(false);
    onClose()
  }

  const roleOptions = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" }

  ]

  const valueOptions = [
    { value: "select", label: "Select" },
    { value: "passport", label: "Passport" },
    { value: "driving license", label: "Driving License" },
    { value: "aadhaar", label: "Aadhaar" }

  ]
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      {!showPopup ? (<div className='flex justify-center rounded-[16px]'>
        <div className="bg-white p-[24px]  w-full overflow-y-auto rounded-[16px]">
          {activeStep === 0 && (<h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">Basic info</h2>)}
          {activeStep === 1 && (<h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">Personal info</h2>)}
          {activeStep === 2 && (<h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">Upload documents</h2>)}
          {activeStep === 3 && (<h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">Bank details</h2>)}
          {activeStep === 4 && (<h2 className="lg:text-[24px] text-[18px]  font-semibold mb-6 text-center text-gray-1 font-public-sans">Add skills</h2>)}
          <div className="flex justify-between relative  gap-[10px]  overflow-x-auto ">

            {steps.map((step, index) => {
              const isActive = index === activeStep;
              const stepNumber = index + 1;

              return (
                isActive && (
                  <div key={index} className="flex-1 items-center relative justify-center  sm:hidden">
                    <div className="flex flex-col items-center justify-center overflow-x-auto min-w-[80px]">
                      <div
                        className={`cursor-pointer rounded-full w-[40px] h-[40px] flex items-center justify-center mx-auto lg:text-[20px] text-[16px] font-public-sans font-medium px-[13.5px] py-[8px] ${isActive ? "bg-btn-gradient text-white" : "bg-gray-5 text-gray-4"} z-10 shadow-md`}
                      >
                        {/* Show step number */}
                        <h2 className={`hidden sm:block`}>{stepNumber}</h2>
                        <h2 className={`block sm:hidden`}>{stepNumber}</h2>
                      </div>

                      <div className={`py-[8px] text-[12px] font-medium font-public-sans ${isActive ? "text-transparent bg-clip-text bg-btn-gradient" : "text-gray-2 hidden"}`}>
                        {/* Show step description */}
                        <div className='hidden sm:block'>{step}</div>
                        <h2 className={`sm:hidden`}>{["Basic info", "Personal info", "Documents", "Bank details", "Skills"][index]}</h2>
                      </div>
                    </div>

                    {/* Display connecting line if not the last step */}
                    {index < steps.length - 1 && (
                      <div className={`absolute inset-1/4 left-1/2 w-full h-0.5 transform -translate-y-1/4 hidden sm:block`}>
                        <div className={`flex-grow h-fit border-t-2 border-dashed ${isActive ? "border-[#f8595c]" : "border-[#828282]"}`}></div>
                      </div>
                    )}
                  </div>
                )
              );
            })}

          </div>
          <div className="flex justify-between relative sm:py-[32px] gap-[10px]  overflow-x-auto ">
            {steps.map((step, index) => (
              <div key={index} className="flex-1 items-center  relative justify-center  hidden sm:block">
                <div className="flex flex-col items-center justify-center overflow-x-auto min-w-[80px]">
                  <div
                    className={`cursor-pointer rounded-full w-[40px] h-[40px] g flex items-center justify-center mx-auto lg:text-[20px] text-[16px] font-public-sans
                  font-medium px-[13.5px] py-[8px] 
                  ${index <= activeStep ? "bg-btn-gradient text-white" : "bg-gray-5 text-gray-4"} z-10 shadow-md `}>
                    {index + 1}
                  </div>
                  <div className={`py-[8px]  text-[12px] font-medium font-public-sans
                  ${index <= activeStep ? "text-transparent bg-clip-text bg-btn-gradient" : "text-gray-2 "} `}>
                    {step}
                  </div>
                </div>
                {index < steps.length - 1 && (

                  <div className={`  absolute inset-1/4 left-1/2  w-full h-0.5  transform -translate-y-1/4`} >
                    <div className={`flex-grow h-fit border-t-2 border-dashed ${index < activeStep ? "border-[#f8595c]" : " border-[#828282]"}`}></div>
                  </div>
                )}
              </div>
            ))}
          </div>



          {activeStep === 0 && (
            <div className='max-h-[650px]'>
              <form>
                <div className="">
                  <label className="block text-[16px] font-mulish font-bold text-gray-1">Full Name</label>
                  <input
                    type="text"
                    placeholder="Enter Employee Name"
                    className="text-gray-4 lg:text-base font-mulish  mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm  text-sm"
                  />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4  mt-[24px] ">
                  <div>

                    <div className=' shadow-sm mt-[8px]    justify-between'>
                      <SelectDropdown
                        label={'Department'}
                        options={[]}
                        selectClass={'w-full'}
                        className={'w-full'}
                        customPadding={'px-4 py-[4.5px] '}
                        customargin={'mt-3'}
                      />
                    </div>
                  </div>
                  <div>

                    <div className='g shadow-sm mt-[8px]   '>
                      <SelectDropdown
                        label={'Position'}
                        options={[]}
                        selectClass={'w-full'}
                        className={'w-full'}
                        customPadding={'px-4 py-[4.5px] '}
                        customargin={'mt-3'}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-[24px] w-full">
                  <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">Date of joining</label>

                  <div className="relative">
                    {/* DatePicker Input */}
                    <DatePicker
                      selected={startDates}
                      onChange={(date) => setStartDates(date)}
                      placeholderText="Select your date"
                      className="form-control lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm sm:text-sm pr-10" // Adjusted padding right for the icon
                    />

                    {/* Calendar Icon */}
                    <div
                      className="absolute inset-y-0 right-0 flex items-center pr-3  cursor-pointer"
                      onClick={() => document.querySelector(".form-control").focus()}
                    >

                      <svgs.CalendarIcon />
                    </div>
                  </div>



                  {/* <div className='absolute   right-4 top-12 '>< Calenderhr/></div> */}
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[24px]">

                  <div>
                    <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">Phone Number</label>
                    <input
                      type="tel"
                      placeholder="+1 000 000 0000"
                      className="lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm sm:text-sm"
                      maxLength="17"
                      onChange={(e) => {
                        // Allow only numeric values and '+' at the beginning
                        const inputValue = e.target.value;
                        const regex = /^\+?\d*$/;

                        // Validate the input and prevent non-numeric characters
                        if (regex.test(inputValue)) {
                          e.target.value = inputValue;
                        } else {
                          e.target.value = inputValue.slice(0, -1); // Remove last invalid character
                        }
                      }}
                    />
                  </div>

                  <div>
                    <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">Email id</label>
                    <input
                      type="email"
                      placeholder="Enter Employee Email"
                      className="lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm sm:text-sm"
                    />
                  </div>
                </div>

              </form>
            </div>
          )}

          {activeStep === 1 && (
            <div className='px-6 max-h-[752px]'>
              <div className="flex items-center justify-center  border w-full rounded-lg  p-4  border-gray-5 ">
                <div className="flex justify-center items-center flex-col">
                  <div className="flex flex-col items-center gap-[10px] mt-[16px]">
                    <label htmlFor="upload-button" className="cursor-pointer">
                      <div className="border-[#f6f6f6] bg-[#f6f6f6] rounded-full flex items-center justify-center px-[18px] py-[40px]">
                        <span > <button className='rounded-lg border border-gray-2 py-[7px] px-[12.5px]  text-gray-2 font-public-sans font-semibold text-sm'>Browse</button></span>
                      </div>
                    </label>
                    <input
                      id="upload-button"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </div>
                  <div className="flex justify-center mb-[16px]">
                    <button className="px-4 py-2 cursor-pointer text-gray-1 font-bold font-mulish text-base">
                      Upload Image
                    </button>
                  </div>
                  {image && (
                    <div className="flex justify-center ">
                      <img src={image} alt="Uploaded" className="w-full h-full object-cover border rounded-lg" />
                    </div>
                  )}
                </div>
              </div>
              {/* dkmdk */}
              <div className='sm:flex  gap-[10px] mt-[24px]'>

                <div className="w-full">
                  <label className=" lg:text-base text-sm font-bold text-gray-1 font-mulish">Identity proof</label>
                  <div className='  shadow-sm mt-[4px]  focus:outline-none focus:ring-red-500 focus:border-red-500  justify-between'>
                    <SelectDropdown label="" options={valueOptions} name="role" id="role" selectClass={'w-full'} className={'w-full '} customPadding={'px-4 py-[4.5px] '} customargin={'mt-3'} />

                  </div>
                </div>
                <div className=" w-full">
                  <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">Unique ID number</label>
                  <input type="text" placeholder="Enter Unique ID Number" name="uniqueIdNumber" value={formData.uniqueIdNumber} onChange={handleChange}
                    className="lg:text-base text-sm font-mulish sm:mt-[11px] lg:mt-[8px] block w-full px-[16px] py-[13.5px] border border-gray-5 rounded-lg shadow-sm  sm:text-sm" />
                </div>
              </div>
              <div className="mb-4 mt-4">
                <label className="block lg:text-base text-sm font-bold text-gray-1 font-mulish">Date of birth</label>
                <div className="relative">
                  {/* DatePicker Input */}
                  <DatePicker
                    selected={startDates}
                    onChange={(date) => setStartDates(date)}
                    placeholderText="Select your date"
                    className="form-control lg:text-base text-sm font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm sm:text-sm pr-10" // Adjusted padding right for the icon
                  />

                  {/* Calendar Icon */}
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3  cursor-pointer"
                    onClick={() => document.querySelector(".form-control").focus()}
                  >

                    <svgs.CalendarIcon />
                  </div>
                </div>

              </div>
              <div className="mb-4">
                <label className=" lg:text-base text-sm font-bold text-gray-1 font-mulish">Marital status</label>
                <div className='"mt-1'>
                  <SelectDropdown label="" options={roleOptions} name="role" id="role" selectClass={'w-full'} className={'w-full '} customPadding={'px-4 py-[4.5px] '} customargin={'mt-3'} />
                </div>
              </div>
            </div>
          )}

          {activeStep === 2 && (

            <div className=" px-6 flex flex-col gap-[24px]  w-full max-h-[650px]">
              <FileUpload label="Offer letter" />
              <FileUpload label="Experience letter" />
              <FileUpload label="Graduation certificate" />
              <FileUpload label="Graduation certificate" />

            </div>


          )}

          {activeStep === 3 && (
            <div className='max-h-[746px]'>
              <form>
                <div className="">
                  <label className="block text-[16px] font-mulish font-bold text-gray-1">National Tax ID</label>
                  <input
                    type="text"
                    placeholder="Enter National Tax ID"
                    className="text-gray-4 lg:text-base font-mulish  mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm  text-sm"
                  />
                </div>

                <div className="mt-6">
                  <label className="block text-[16px] font-mulish font-bold text-gray-1">Account number</label>
                  <input
                    type="text"
                    placeholder="Enter Account Number"
                    className="text-gray-4 lg:text-base font-mulish  mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm text-sm"
                  />
                </div>

                <div className="mt-6">
                  <label className="block text-[16px] font-mulish font-bold text-gray-1">IFSC code</label>
                  <input
                    type="text"
                    placeholder="Enter IFSC Code"
                    className="text-gray-4 lg:text-base font-mulish  mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm text-sm"
                  />
                </div>

                <div className="mt-6">
                  <label className="block text-[16px] font-mulish font-bold text-gray-1">Bank name</label>
                  <input
                    type="text"
                    placeholder="Enter Bank Name"
                    className="text-gray-4 lg:text-base font-mulish  mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 text-sm"
                  />
                </div>

                <div className="mt-6">
                  <label className="block text-[16px] font-mulish font-bold text-gray-1">Name on account</label>
                  <input
                    type="text"
                    placeholder="Enter Name on Account"
                    className="text-gray-4 lg:text-base font-mulish  mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm text-sm"
                  />
                </div>
              </form>
            </div>
          )}

          {activeStep === 4 && (
            <div className="flex  max-[]">
              <div className="w-full">
                <div>
                  <label className="block text-[16px] font-mulish font-bold text-gray-1 ">Add skills</label>
                  <input
                    type="text"
                    value={skill}
                    onChange={(e) => setSkill(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleAddSkill()} // Add skills on Enter key press
                    className="leading-tight text-gray-4 lg:text-base font-mulish mt-[8px] block w-full px-[16px] py-[12.5px] border border-gray-5 rounded-lg shadow-sm text-sm"
                    placeholder="Type skill"
                  />
                </div>

                <div className="flex flex-wrap gap-2 mt-[24px] justify-center">
                  {skills.map((s) => (
                    <div
                      key={s}
                      className="flex gap-[10px] items-center border border-gray-5 rounded-[100px] px-[14px] py-[7px] relative"
                    >
                      <span className="mr-2">{s}</span>
                      <button
                        onClick={() => handleRemoveSkill(s)}
                        className="text-gray-1 font-public-sans text-base hover:text-gray-700"
                      >
                        <span className="absolute -top-1 -right-1">
                          <Crossiconssvg /> {/* Kept the SVG as you requested */}
                        </span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-center mt-[32px] gap-[10px] ">
            <button onClick={prevsStep} disabled={activeStep === 0} className={`${activeStep === 0 ? 'hidden' : ''} w-[162px] py-[14px] px-[24px] font-mulish 
      border rounded-lg border-[#f65154] font-bold lg:text-base text-sm text-transparent bg-clip-text bg-btn-gradient`}>Back</button>



            {activeStep !== 4 ? (
              <button onClick={handleNextClick} disabled={activeStep === steps.length - 1} className="lg:text-base w-[162px] text-sm font-mulish font-bold 
         py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
         shadow-sm hover:bg-red-600 focus:outline-none">{activeStep === 4 ? 'Submit' : 'Next'}
              </button>
            ) : (
              <>
                <button onClick={handleShowPopup} className="lg:text-base w-[162px] text-sm font-mulish font-bold 
        py-[14px] px-[24px] bg-btn-gradient text-white rounded-lg
        shadow-sm hover:bg-red-600 focus:outline-none ">{activeStep === 4 ? 'Submit' : 'Next'}
                </button>

              </>
            )}

          </div>
        </div>
      </div>
      ) : (<SuccessfulDialog heading={'Employee added successfully'} show={showPopup} onClose={handleClosePopup} />)}
    </>


  )
}

export default Addnewemployee
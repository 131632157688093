import React, { useState } from 'react';
import Svgs from 'assets/svgs/Index';
import MoreTaskoptions from './Element/MoreTaskoptions';
import ShareGroceryDialog from '../../../Pages/UserDashboard/Chat/Elements/ShareGroceryDialog';
import GroceryDialog from './Element/GroceryDialog';

function TaskDataCard({ title, value1, value2, description, bgcolor, textcolor }) {
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showGroceryDialog, setShowGroceryDialog] = useState(false);

  const [showGrocery, setShowGrocery] = useState(false)


  // To ensure only one popup opens at a time, we need to close others when one opens.
  const closeAllPopups = () => {
    setShowMoreOptions(false);
    setShowSharePopup(false);
    setShowGroceryDialog(false);
  };

  // Handle card click to show Grocery dialog
  const handleCardClick = () => {
    if (!showMoreOptions && !showSharePopup) { // Ensure the Grocery dialog only opens when other popups are not open
      closeAllPopups(); // Close any open popups
      setShowGroceryDialog(true); // Open Grocery dialog
    }
  };

  // Handle the 3 dots (more options) click to show MoreTaskOptions popup
  const handleMoreOptionsClick = (event) => {
    event.stopPropagation(); // Prevent triggering card click
    closeAllPopups(); // Close any open popups
    setShowMoreOptions(true); // Open MoreTaskOptions popup
  };

  // Handle share icon click to show ShareGroceryDialog popup
  const handleShareClick = (event) => {
    event.stopPropagation(); // Prevent triggering card click
    closeAllPopups(); // Close any open popups
    setShowSharePopup(true); // Open ShareGroceryDialog popup
  };

  return (
    <>

      <div  className='relative   border rounded-xl bg-white p-[16px] mb-5 sm:mx-2 w-[91vw] sm:w-[288px] cursor-pointer '>
        {/* Show GroceryDialog only if it's open */}
        <div  onClick={() => setShowGrocery(true)}>
        <div  className='flex items-center justify-between'>
          <div  className='cursor-pointer  '>
            <h1 className={` ${bgcolor} ${textcolor} w-fit py-2 px-3 rounded-full text-xs font-semibold font-public-sans`}>
              {title}
              
            </h1>
          </div>
          
        </div>

        <div className='my-5'>
          <h1 className='text-base font-semibold font-public-sans text-gray-1'>{description}</h1>
        </div>

        <div  className='flex items-center justify-between'>
          <div className='flex items-center gap-5'>
            <div className='flex items-center gap-2'>
              <Svgs.DocumentIcon />
              <h1 className='text-xs font-normal font-public-sans text-gray-4'>{value1}</h1>
            </div>
            <div className='flex items-center gap-2'>
              <Svgs.Message />
              <h1 className='text-xs font-normal font-public-sans text-gray-4'>{value2}</h1>
            </div>
          </div>
          <div className="flex items-center ">
            <img src={"/images/groupimg1.png"} width={'32px'} className=" -mr-2" alt="" />
            <img src={"/images/groupimg2.png"} width={'32px'} className="-mr-2" alt="" />
            <img src={"/images/groupimg3.png"} width={'32px'} alt="" />
          </div>
        </div>

        </div>
        <div className='flex items-center gap-3 absolute  top-3 right-2'>
            <div className='bg-[#f3f3f3] rounded-full w-7 h-7 flex items-center justify-center'>
              <button onClick={handleShareClick}>
                <Svgs.ShareIcon />
              </button>
              {/* Show ShareGroceryDialog only if it's open */}
              {showSharePopup && (
                <ShareGroceryDialog show={showSharePopup} onClose={() => setShowSharePopup(false)} />
              )}
            </div>
            <div className='cursor-pointer '>
              <button onClick={handleMoreOptionsClick} className=' p-3'>
                <Svgs.Verticaldots />
              </button>
              {/* Show MoreTaskoptions only if it's open */}
              {showMoreOptions && (
                <MoreTaskoptions show={showMoreOptions} onClose={() => setShowMoreOptions(false)} />
              )}
            </div>
          </div>
      </div>

      <GroceryDialog showGrocery={showGrocery} setShowGrocery={setShowGrocery} />

    </>
  );
}

export default TaskDataCard;

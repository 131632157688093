import React from 'react'
import Button from "../Button";
import Svgs from 'assets/svgs/Index';
import PopupModal from 'components/common/popup-modal/popup-modal';

const SuccessfulTask = ({ showShareGroceryCopy, setShowShareGroceryCopy, showSuccessTask, setShowSuccessTaskheading, heading }) => {
  return (
    <div>
      <PopupModal isOpenModal={showShareGroceryCopy} setIsOpenModal={setShowShareGroceryCopy}>
        <div className=" flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className=" p-5 scroll-smooth bg-white shadow-lg rounded-xl border w-[90vw] md:w-[360px]">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-center text-center">
                <span className="flex items-center justify-center text-center h-9 w-9 bg-[#60c26f] rounded-full">
                  <Svgs.TickIcon />
                </span>
              </div>
              <div className="w-[60%] flex items-center justify-center text-center mx-auto">
                <h1 className="text-base font-normal font-public-sans text-gray-1">{heading}</h1>
              </div>
              <div className="flex items-center justify-center mt-5">
                <Button
                  // onClick={() => setShowShareGroceryCopy(false)}
                  handleClick={() => setShowShareGroceryCopy(false)}

                  text={'Close'} customPadding={'px-[24px] py-[14px]'} className={'h-[48px] w-[162px]'} />
              </div>
            </div>
          </div>
        </div>
      </PopupModal>
    </div>
  )
}

export default SuccessfulTask

// import React, { useState } from "react";
// import Svgs from "../../../../assets/svgs/Index";

// const UploadFileModal = ({ isOpen, onClose, onSave }) => {
//   const [uploadedFile, setUploadedFile] = useState(null);

//   const handleFileUpload = (event) => {
//     setUploadedFile(event.target.files[0]);
//   };

//   const handleSave = () => {
//     if (uploadedFile) {
//       onSave(uploadedFile);
//       onClose();
//     } else {
//       alert("Please upload a file before saving.");
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       {/* Modal Container */}
//       <div className="w-[408px] min-h-[259px] max-h-[289px] bg-white p-[24px] rounded-[16px]  shadow-lg">
//         {/* Title */}
//         <h2 className="text-[24px] font-semibold leading-[28.2px]  font-public-sans flex justify-center items-center  text-gray-1 ">
//           Upload salary slip
//         </h2>

//         {/* Upload Input Section */}
//         <div className="mt-[32px]">
//             <h2 className="font-mulish font-bold text-[16px] tracking-[0.02em] leading-[19px] text-gray-1 mb-[8px]">Upload salary slip</h2>
        
//         <div className="relative flex items-center justify-between w-[360px] h-[44px] border border-gray-5 rounded-[8px] px-4   ">
//           <span className="text-gray-1 tracking-[0.05em] leading-[19px] text-[16px] font-normal font-mulish">Upload</span>
//           <Svgs.FileUploadPayroll className="absolute right-4" />
//           <input
//             type="file"
//             className="absolute inset-0 opacity-0 cursor-pointer"
//             onChange={handleFileUpload}
//           />
//         </div>
//         </div>
//         {/* Uploaded File Name */}
//         {uploadedFile && (
//           <p className="mt-2 text-sm text-[#00B037] tracking-[0.02em] font-public-sans">File: {uploadedFile.name}</p>
//         )}

//         {/* Save Button */}
//         <button
//           className="mt-[32px] w-[162px] h-[48px] bg-b-gradient text-white text-sm rounded-[8px] mx-auto flex justify-center items-center  tracking-[0.05em] leading-[19px] text-[16px] font-bold font-mulish"
//           onClick={handleSave}
//         >
//           Save
//         </button>
//       </div>
//     </div>
//   );
// };

// export default UploadFileModal;
import React, { useState, useRef, useEffect } from "react";
import Svgs from "../../../../assets/svgs/Index";

const UploadFileModal = ({ isOpen, onClose, onSave }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const modalRef = useRef(null);

  const handleFileUpload = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  const handleSave = () => {
    if (uploadedFile) {
      onSave(uploadedFile);
      onClose();
    } else {
      alert("Please upload a file before saving.");
    }
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      {/* Modal Container */}
      <div
        ref={modalRef}
        className="sm:w-[408px] w-[90%] min-h-[259px] max-h-[289px] bg-white p-[24px] rounded-[16px] shadow-lg"
      >
        {/* Title */}
        <h2 className="text-[24px] font-semibold leading-[28.2px] font-public-sans flex justify-center items-center text-gray-1">
          Upload salary slip
        </h2>

        {/* Upload Input Section */}
        <div className="mt-[32px]">
          <h2 className="font-mulish font-bold text-[16px] tracking-[0.02em] leading-[19px] text-gray-1 mb-[8px]">
            Upload salary slip
          </h2>

          <div className="relative flex items-center justify-between sm:w-[360px] w-[98%] h-[44px] border border-gray-5 rounded-[8px] px-4">
            <span className="text-gray-1 tracking-[0.05em] leading-[19px] text-[16px] font-normal font-mulish">
              Upload
            </span>
            <Svgs.FileUploadPayroll className="absolute right-4" />
            <input
              type="file"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={handleFileUpload}
            />
          </div>
        </div>
        {/* Uploaded File Name */}
        {uploadedFile && (
          <p className="mt-2 text-sm text-[#00B037] tracking-[0.02em] font-public-sans">
            File: {uploadedFile.name}
          </p>
        )}

        {/* Save Button */}
        <button
          className="mt-[32px] w-[162px] h-[48px] bg-b-gradient text-white text-sm rounded-[8px] mx-auto flex justify-center items-center tracking-[0.05em] leading-[19px] text-[16px] font-bold font-mulish"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default UploadFileModal;


import React, { useState, useRef, useEffect } from "react";
import { Calenderhr } from "assets/svgs/Index";

function CustomDropdown({ label, options, name, id, onChange = () => {}, className }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.find(option => option.label === label));
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange({ target: { name, value: option.value } });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className={`rounded-lg px-3 py-1 flex justify-between items-center border ${className}`}
        onClick={() => setIsOpen(!isOpen)}
        style={{ width: '110px' }}  // Fixed width
      >
        <span className="text-base font-normal font-public-sans text-gray-2">
          {selectedOption.label}
        </span>
        <Calenderhr style={{ width: '24px', height: '24px' }} />
      </button>
      {isOpen && (
        <ul className="absolute left-0 w-full border border-gray-300 rounded-lg bg-white shadow-lg z-10 mt-1 max-h-[300px] overflow-y-auto">
          {options.map((option, index) => (
            <li
              key={index}
              className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${option.value === selectedOption.value ? 'bg-gray-200' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CustomDropdown;

export const attendance = [
    {
        name: "Anna",
        role: "Python developer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(anna).png",
      },
      {
        name: "Catherine",
        role: "Web designer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(catherine5).png",
      },
      {
        name: "Orton",
        role: "UI UX designer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(ortan6).png",
      },
      {
        name: "Jessy",
        role: "Web developer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(jessy7).png",
      },
      {
        name: "Orton",
        role: "UI UX designer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(ortan6).png",
      },
  
      {
        name: "Orton",
        role: "UI UX designer",
        status: "Present",
        time: "10:00AM",
        img: "/images/(ortan6).png",
      },
  
]
import React, { useState, useRef, useEffect } from "react";
import Svgs from "assets/svgs/Index.js";
import UploadFileModal from "../Pages/HRDashboard/Payroll/Elements/UploadSalaryModal";

function PayrollDropdown({ label, options, name, id, onChange = () => {}, className = "", customWidth = "", customHeight = "", customPadding = "", customMargin = "" }) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    
    (options.length > 0 ? options[0] : { value: "", label: "Select" })
  );
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);

    if (option.value === "Paid") {
      setModalOpen(true);
    }

    
    console.log('options======>',option)
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleFileSave = (file) => {
    console.log("File uploaded:", file);
    alert("File successfully uploaded!");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const buttonStyles = {
    "In progress": "bg-[#faf2e4] text-[#F4A012]",
    Pending: "bg-[#faeaea] text-[#F54D4D]",
    Paid: "bg-[#e2f3e7] text-[#00B037]",
  };

  return (
    <div className={`flex flex-col gap-y-1 bg-whit ${className}`} ref={dropdownRef}>
      {label && (
        <label htmlFor={id} className="text-base font-bold font-mulish text-gray-1">
          {label}
        </label>
      )}
      <div className={`relative ${customWidth} ${customHeight} ${customPadding}`}>
        <button
          type="button"
          className={`flex outline-none w-[110px] h-[28px] rounded-[16px] px-[6px] justify-between items-center ${
            buttonStyles[selectedOption.value] || "bg-gray-400 text-black"
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="whitespace-nowrap">{selectedOption.label}</span>
          <Svgs.Dropdown />
        </button>
        {isOpen && (
          <ul
            className={`absolute mt-2 w-[110px] border rounded-lg bg-white shadow-lg z-10 max-h-60 overflow-auto ${customMargin}`}
          >
            {options.length === 0 ? (
              <li className="px-3 py-2 cursor-not-allowed text-gray-500">No Records</li>
            ) : (
              options.map((option, index) => (
                <li
                  key={index}
                  className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${
                    option.value === selectedOption.value ? "bg-gray-200" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
      <UploadFileModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleFileSave}
      />
    </div>
  );
}

export default PayrollDropdown;

import zIndex from "@mui/material/styles/zIndex";
import React from "react";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "12px",
        padding: "0px",
        width: "fit-content",
        height: "fit-content",
        zIndex: "1000000"
    },
};

const PopupModal = ({ isOpenModal, setIsOpenModal, children }) => {
    function closeModal() {
        setIsOpenModal(false);
    }

    return (
        <Modal
            isOpen={isOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
        >
            {children}
        </Modal>
    );
};
export default PopupModal;

import React from "react";
import { useState, useEffect, useRef } from "react";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import Svgs from 'assets/svgs/Index.js'
import CreateTaskDialog from "./CreateTaskDialog.jsx";
import EditGroceryDialog from "./EditGroceryDialog.jsx";
import ShareGroceryDialog from "../../../UserDashboard/Chat/Elements/ShareGroceryDialog.jsx";
import PopupModal from "components/common/popup-modal/popup-modal.jsx";
import { Input } from "components/Input.jsx";
import SelectDropdown from "components/SelectDropdown.jsx";
import SuccessfulTask from "components/Elements/SuccessfulTask.jsx";


const optionsRole = [
  { value: "Priority", label: "Priority" },
  { value: "Edit", label: "Edit" },
  { value: "Delete", label: "Delete" }
]


function GroceryDialog({ show, onClose, hidden, popupCardIndex, index, setShowPopUp, showGrocery, setShowGrocery }) {
  const [showEditGrocery, setShowEditGrocery] = useState(false)
  //  for share state
  const [showShareGrocery, setShowShareGrocery] = useState(false)

  // for share statet copy succeful link
  const [showShareGroceryCopy, setShowShareGroceryCopy] = useState(false)

  {/* add atachment modal */ }
  const [showEditGroceryAttachment, setShowEditGroceryAttachment] = useState(false)

  // for share statet Attachment succeful link
  const [showSuccessTask, setShowSuccessTask] = useState(false)



  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [isVisible, setIsVisible] = useState(true)

  // console.log('show', show)

  const handleRemove = () => {
    setIsVisible(false)
  }


  const [isVisible1, setIsVisible1] = useState(true)

  const handleRemove1 = () => {
    setIsVisible1(false)
  }

  const [teamMembers, setTeamMembers] = useState([{ id: 1 }, { id: 2 }])


  const removeMember = (id) => {
    setTeamMembers(teamMembers.filter(member => member.id !== id));
  };


  const [showPopup, setShowGroceryPopup] = useState(false);

  const handleShowPopup = () => {
    setShowGroceryPopup(true);
  };

  const handleClosePopup = () => {
    setShowGroceryPopup(false);
  };



  const [showEditPopup, setShowEditPopup] = useState(false);

  const handleShowEditPopup = () => {
    setShowEditPopup(true);
    // setShowPopUp(false)
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
  };


  const [showEditPopup2, setShowEditPopup2] = useState(false);

  const handleShowEditPopup2 = () => {
    setShowEditPopup2(true);

  };

  const handleCloseEditPopup2 = () => {
    setShowEditPopup2(false);
    setShowGroceryPopup(false)
  };


  const [showGrocerySharePopup, setShowGrocerySharePopup] = useState(false);

  const handleShowGrocerySharePopup = () => {
    setShowGrocerySharePopup(true);
  };

  const handleCloseGrocerySharePopup = () => {
    setShowGrocerySharePopup(false);
  };


  const [showGrocerySharePopup2, setShowGrocerySharePopup2] = useState(false);

  const handleShowGrocerySharePopup2 = () => {
    setShowGrocerySharePopup2(true);
  };

  const handleCloseGrocerySharePopup2 = () => {
    setShowGrocerySharePopup2(false);
  };




  const popupRef = useRef();

  // const handleClickOutside = (event) => {
  //   if (popupRef.current && !popupRef.current.contains(event.target)) {
  //     onClose();
  //   }
  // };



  const [selectedValue, setSelectedValue] = useState("Easy"); // Default value

  const handleChange = (e) => {
    setSelectedValue(e.target.value); // Update the state with the selected option
  };


  // useEffect(() => {
  //   if (show || showPopup) {
  //     // Disable scrolling on the background when the popup is open
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     // Enable scrolling again when the popup is closed
  //     document.body.style.overflow = "auto";
  //   }

  //   return () => {
  //     // Clean up by enabling scrolling when the component unmounts
  //     document.body.style.overflow = "auto";
  //   };
  // }, [show, showPopup]);



  // useEffect(() => {
  //   if (show) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [show]);

  // if (!show) return null;

  return (
    <>
      <PopupModal isOpenModal={showGrocery} setIsOpenModal={setShowGrocery}>
        <div className="flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="grocery-scrollbar-none overflow-y-auto h-[80vh] rounded-xl ">
            <div ref={popupRef} className="bg-white w-[95vw] sm:max-w-[80vw] md:max-w-[848px]  p-3 md:p-5 ">
              <div className="flex items-center">
                <div className="hidden md:block w-[40%]">
                </div>
                <div className="flex items-center flex-wrap  justify-center md:justify-between gap-3 w-full md:w-[70%]">

                  <div className="">
                    <h1 className=" text-lg :text-2xl font-semibold font-public-sans text-gray-1">
                      Grocery dashboard
                    </h1>
                  </div>
                  <div className=" hidden lg:flex flex-wrap items-center justify-end gap-3">
                    <button
                      onClick={() => {
                        setShowEditGrocery(true)
                        setShowGrocery(false)
                      }}
                      className=" text-base font-normal font-public-sans text-gray-2 flex items-center gap-2 border py-1 px-3 rounded-lg">
                      <Svgs.WritingPencilBlue />
                      Edit
                    </button>
                    {/* {showEditPopup && (<EditGroceryDialog show={showEditPopup} onClose={handleCloseEditPopup} />)} */}
                    <button
                      onClick={() => {
                        setShowShareGrocery(true)
                        setShowGrocery(false)
                      }}
                      className=" text-base font-normal font-public-sans text-gray-2 flex items-center gap-2 border py-1 px-3 rounded-lg">
                      <Svgs.ShareIconBlue />
                      Share
                    </button>

                  </div>
                </div>
              </div>
              <div className="flex md:items-center justify-between mt-10 cu">
                <div className="flex flex-col gap-2">
                  <div>
                    <h1 className=" text-sm font-normal font-public-sans text-gray-2">
                      Members
                    </h1>
                  </div>
                  <div className="flex items-center gap-3">
                    <img className="w-[40px] h-[40px]" src="/images/ortan (1).png" alt="" />
                    <img className="w-[40px] h-[40px]" src="/images/ortan (2).png" alt="" />
                    <img className="w-[40px] h-[40px]" src="/images/ortan (3).png" alt="" />
                    <span className=" bg-[#e0e0e0] h-7 w-7 rounded-full flex items-center justify-center"><Svgs.AddIconGray /></span>
                  </div>
                  <div className="lg:hidden flex flex-wrap items-center justify-end gap-3 my-4">
                    <button

                      onClick={() => {
                        setShowEditGrocery(true)
                        setShowGrocery(false)
                      }}
                      className=" text-base font-normal font-public-sans text-gray-2 flex items-center gap-2 border py-1 px-3 rounded-lg">
                      <Svgs.WritingPencilBlue />
                      Edit
                    </button>
                    {/* {showEditPopup2 && (<EditGroceryDialog show={showEditPopup2} onClose={handleCloseEditPopup2} />)} */}


                    <button
                      onClick={() => {
                        setShowShareGrocery(true)
                        setShowGrocery(false)
                      }}
                      className=" text-base font-normal font-public-sans text-gray-2 flex items-center gap-2 border py-1 px-3 rounded-lg">
                      <Svgs.ShareIconBlue />
                      Share
                    </button>
                    {popupCardIndex === index && showGrocerySharePopup2 && (<ShareGroceryDialog show={showGrocerySharePopup2} onClose={handleCloseGrocerySharePopup2} />)}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className=" text-[10px] md:text-sm font-normal font-public-sans text-gray-2">
                    Priority
                  </h1>

                  <span className="bg-[#fff7ea] rounded-full px-[14px]">
                    <select
                      className="bg-transparent text-[#f4a012] text-[10px] md:text-xs font-semibold font-public-sans py-[10px] outline-none"
                      value={selectedValue} // Bind the state to the select element
                      onChange={handleChange} // Update the state on change
                      name="difficulty"
                      id="difficulty-select"
                    >
                      <option
                        value="Easy"
                        className="text-[#f4a012] text-xs font-semibold font-public-sans"
                      >
                        Easy
                      </option>
                      <option
                        value="Medium"
                        className="text-[#f4a012] text-xs font-semibold font-public-sans"
                      >
                        Medium
                      </option>
                      <option
                        value="High"
                        className="text-[#f4a012] text-xs font-semibold font-public-sans"
                      >
                        High
                      </option>
                    </select>
                  </span>
                </div>
              </div>
              <div className=" my-5 cursor-text">
                <div>
                  <h1 className=" text-sm font-normal font-public-sans text-gray-1">
                    Description
                  </h1>
                </div>
                <div className=" border rounded-xl p-5 mt-2">
                  <p className=" text-xs md:text-base font-normal font-public-sans text-gray-4">
                    The dashboard of the MR Corporation Grocery Mobile App is crafted
                    to deliver a seamless and intuitive user experience, ensuring
                    users can effortlessly manage their grocery needs. The design
                    philosophy centers around clarity, accessibility, and efficiency.{" "}
                  </p>
                  <ul className=" list-disc px-4 my-4">
                    <li className=" text-xs md:text-base font-normal font-public-sans text-gray-4">
                      Clean Layout: A minimalist design with a focus on essential
                      elements, avoiding clutter.
                    </li>
                    <li className=" text-xs md:text-base font-normal font-public-sans text-gray-4">
                      Intuitive Navigation: Easy-to-understand icons and menus for
                      quick access to various sections like shopping lists, order
                      history, and account settings.
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div>
                  <h1 className="text-sm font-normal font-public-sans text-gray-1">
                    Attachments
                  </h1>
                </div>
                <div className="my-4 border-b border-dashed mb-[16px]">
                  <div className="flex items-center flex-wrap justify-between gap-4 my-[16px] ">
                    {isVisible && (
                      <button className=" border rounded-xl px-[14px] py-[10px] bg-[#f3f3f3]  flex items-center justify-between w-full lg:w-[273px] relative">
                        <span className=" text-sm font-semibold text-gray-2 font-public-sans">
                          Detailed document
                        </span>
                        <span className=" rounded-full bg-white">
                          <Svgs.Oction_download />
                        </span>
                        <span onClick={handleRemove} className=" absolute -top-2 -right-2">
                          <Svgs.Cross />
                        </span>
                      </button>
                    )}
                    {isVisible1 && (
                      <button className=" border rounded-xl px-[14px] py-[10px] bg-[#f3f3f3]  flex items-center justify-between w-full lg:w-[273px] relative">
                        <span className=" text-sm font-semibold text-gray-2 font-public-sans">
                          Detailed document
                        </span>
                        <span className=" rounded-full bg-white">
                          <Svgs.Oction_download />
                        </span>
                        <span onClick={handleRemove1} className=" absolute -top-2 -right-2">
                          <Svgs.Cross />
                        </span>
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setShowEditGroceryAttachment(true)
                        setShowGrocery(false)
                      }}
                      className=" border border-[#f7585c] rounded-xl px-[12px] py-[11.5px] flex items-center gap-4 ">
                      <span><Svgs.DocumentRedIcon /></span>
                      <h1 className="text-[#f7585c] text-base font-medium font-public-sans">Add attachments</h1>
                    </button>

                  </div>
                </div>
              </div>
              <div>
                {/* <div className="mt-[48px]">
              <label htmlFor="" className="text-sm font-normal font-public-sans text-gray-2">Comments & discussions</label>
              <div className="flex items-center gap-4 my-2">
                <input type="text" className=" w-full border px-[14px] py-[10px] rounded-xl" /><Button text={'Send'} customPadding={'px-[14px] py-[10px]'} />
              </div>
            </div> */}
                <div className="mt-[48px]">
                  <label htmlFor="" className="text-sm font-normal font-public-sans text-gray-2">Comments & discussions</label>
                  <div className="flex items-center gap-4 my-2">
                    <input
                      type="text"
                      className="w-full border px-[14px] py-[10px] rounded-xl text-gray-3 font-public-sans text-[14px] placeholder:text-gray-3 placeholder:font-public-sans placeholder:text-[14px]"
                      placeholder="Type your comment"
                    />
                    <Button text={'Send'} customPadding={'px-[14px] py-[10px]'} />
                  </div>
                </div>

              </div>
              {Array(4).fill().map((_, index, arr) => (

                <div key={index} className={`px-[12px] py-[20px] ${index === arr.length - 1 ? ' border-b-0' : 'border-b-2'}`}>
                  <div className="flex justify-between">
                    <div className="leftpart flex items-center gap-4">
                      <div>
                        <img src="/images/beautifulGirlpic.png" alt="" className=" w-[28px]" />
                      </div>
                      <div>
                        <h1 className=" text-xs md:text-sm font-semibold font-public-sans text-gray-1">Anna</h1>
                        <h1 className=" text-sm font-normal font-public-sans text-gray-2">On which file I need to start the work?</h1>
                      </div>
                    </div>
                    <div className="rightpart flex gap-1 md:gap-[14px]">
                      <span className=" text-[10px] md:text-xs font-public-sans font-normal text-[#497CFF] flex gap-2"><Svgs.ReplyIcon />Reply</span>
                      <h1 className="text-xs font-public-sans font-normal text-gray-4 md:block hidden">5 hours ago</h1>
                      <h1 className="text-xs font-public-sans font-normal text-gray-4 md:hidden">5H ago</h1>
                    </div>
                  </div>
                </div>

              ))}
            </div>
          </div>
        </div>
      </PopupModal>

      {/* for edit popup */}
      <PopupModal isOpenModal={showEditGrocery} setIsOpenModal={setShowEditGrocery}>

<div className="flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div ref={popupRef} className="grocery-scrollbar-none bg-white w-full md:w-[848px] rounded-xl p-5 overflow-auto h-[80vh]">
    <div className="flex items-center justify-center">
      <h1 className="text-lg md:text-2xl font-semibold font-public-sans text-gray-1">Edit Grocery dashboard</h1>
    </div>

    <div className="my-5 mt-[48px]">
      <Input label={"Task name"} placeholder={'Task name'} />
      <h1 className="text-base font-bold font-mulish text-gray-1">Description</h1>
      <div className="border rounded-xl p-5 cursor-text">
        <p className="text-xs md:text-base font-normal font-public-sans text-gray-4 cursor-text ">
          The dashboard of the MR Corporation Grocery Mobile App is crafted to deliver a seamless and intuitive user experience...
        </p>
        <ul className="list-disc px-4 my-4">
          <li className="text-xs md:text-base font-normal font-public-sans text-gray-4">Clean Layout...</li>
          <li className="text-xs md:text-base font-normal font-public-sans text-gray-4">Intuitive Navigation...</li>
        </ul>
      </div>
    </div>

    <div>
      <h1 className="text-sm font-normal font-public-sans text-gray-1">Attachments</h1>
      <div className="my-4">
        <div className="flex items-center flex-wrap justify-center md:justify-between gap-4 my-[16px]">
          {isVisible && (
            <button className="relative border rounded-xl px-[14px] py-[10px] bg-[#f3f3f3] flex items-center justify-between w-full sm:w-[273px]">
              <span className="text-sm font-semibold text-gray-2 font-public-sans">Detailed document</span>
              <span className="rounded-full bg-white"><Svgs.Oction_download /></span>
              <span onClick={handleRemove} className="absolute -top-2 -right-2"><Svgs.CrossIconRed /></span>
            </button>
          )}
          {isVisible1 && (
            <button className="relative border rounded-xl px-[14px] py-[10px] bg-[#f3f3f3] flex items-center justify-between w-full sm:w-[273px]">
              <span className="text-sm font-semibold text-gray-2 font-public-sans">Detailed document</span>
              <span className="rounded-full bg-white"><Svgs.Oction_download /></span>
              <span onClick={handleRemove1} className="absolute -top-2 -right-2"><Svgs.CrossIconRed /></span>
            </button>
          )}
          <button className="border border-[#f7585c] rounded-xl px-[12px] py-[11.5px] flex items-center gap-4">
            <span><Svgs.DocumentRedIcon /></span>
            <h1 className="text-[#f7585c] text-base font-medium font-public-sans">Add attachments</h1>
          </button>
        </div>
      </div>
    </div>

    <div className="flex items-center justify-between my-6">
      <div className="flex flex-col gap-2">
        <h1 className="text-sm font-normal font-public-sans text-gray-2">Members</h1>
        <img onClick={() => { navigate('/user/myteamlast'); }} src="/images/Member4.png" alt="" className="w-[42px] md:w-fit" />
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="text-sm font-normal font-public-sans text-gray-2">Priority</h1>
        <SelectDropdown options={[{ value: 'Easy', label: 'Easy' }, { value: 'Medium', label: 'Medium' }, { value: 'High', label: 'High' }]} className={'w-[105px] '} />
      </div>
    </div>

    <div className="flex items-center justify-center mt-[48px]">
      <Button
        handleClick={() => setShowEditGrocery(false)}
        // handleClick={setShowEditGrocery(false)}
        text={'Update'} className={'w-[162px] h-[48px]'} customPadding={'px-[24px] py-[14px]'} />
    </div>
  </div>
</div>
</PopupModal>
      {/* <EditGroceryDialog showEditGrocery={showEditGrocery} setShowEditGrocery={setShowEditGrocery} /> */}


      {/* for share popup */}
      <PopupModal isOpenModal={showShareGrocery} setIsOpenModal={setShowShareGrocery}>
        <div className=" flex items-center justify-center bg-white bg-opacity-30 z-50">
          <div ref={popupRef} className=" p-5 scroll-smooth bg-white shadow-lg border rounded-xl max-w-[600px] md:w-[400px] lg:w-[528px]">
            <div>
              <div className="flex items-center justify-center">
                <h1 className=" md:text-2xl font-semibold font-public-sans text-gray-1">Share Grocery dashboard</h1>
              </div>
              <div className=" relative mt-[48px]">
                <Input label={'Sharing link'} value={'Grocerydashobard232Wlfgewe45dgfdsk'} className={' text-xs md:text-base'} />
                <span onClick={() => {
                  setShowShareGroceryCopy(true)
                  setShowShareGrocery(false)
                }}
                  className=" cursor-pointer absolute top-9 md:top-10 right-1 md:right-4"><Svgs.CopyIcon />
                </span>

              
              </div>
              <div className="flex flex-col items-center mt-5">
                <h1 className=" text-base font-mulish font-bold text-gray-1 my-2">Share QR code</h1>
                <span><img src="/images/qrcode.png" width={200} alt="" /></span>
              </div>
              <div className="flex items-center justify-center gap-4 mt-[48px]">
                <button className=" text-xs md:text-base font-bold font-mulish text-gray-2 flex items-center gap-[6px] px-[14.5px] py-[11px] rounded-xl border border-gray-2">
                  <Svgs.ShareGray /> Share QR code
                </button>
                <button className=" text-xs md:text-base font-bold font-mulish text-gray-2 flex items-center gap-[6px] px-[14.5px] py-[11px] rounded-xl border border-gray-2">
                  <Svgs.ShareGray /> Share link
                </button>
              </div>
            </div>
          </div>
        </div>
      </PopupModal>

      <SuccessfulTask showShareGroceryCopy={showShareGroceryCopy} setShowShareGroceryCopy={setShowShareGroceryCopy} heading={'Link Copied'} />
        {/* <ShareGroceryDialog  howShareGrocery={showShareGrocery} setShowShareGrocery={setShowShareGrocery}   setShowShareGroceryCopy={ setShowShareGroceryCopy} showShareGroceryCopy={showShareGroceryCopy} heading={'Link '}/> */}
  
      <PopupModal isOpenModal={showEditGroceryAttachment} setIsOpenModal={setShowEditGroceryAttachment}>
        <div className=" flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div ref={popupRef} className="p-5 bg-white shadow-lg rounded-xl border w-[95vw] sm:w-[500px] md:w-[600px] lg:w-[748px] overflow-y-auto ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-center text-center">
                <h1 className="text-2xl font-semibold font-public-sans text-gray-700">
                  Create new Task
                </h1>
              </div>
              <div>
                <Input label={"Task name"} className={""} placeholder={'Task name'} />
              </div>
              <div>
                <label
                  htmlFor=""
                  className="text-base font-bold font-mulish text-gray-700"
                >
                  Description
                </label>
                <textarea
                  className="focus:ring-1 ring-black resize-none rounded-xl border w-full h-[130px] p-4 my-2 outline-none"
                  placeholder="Description"
                ></textarea>
              </div>
              <div>
                <button className=" text-sm font-semibold font-public-sans text-gray-2 flex items-center justify-center gap-[11px] px-[16px] py-[12px] border border-[#4f4f4f] rounded-xl w-full border-dashed"><Svgs.AddIconGray />Add attachment</button>
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between gap-2">
                <div className=" w-full">
                  <button className="flex items-center justify-center w-full md:w-[258px] h-[44px] gap-3 border border-[#FB6D72] rounded-xl text-[#F33F41] text-sm font-semibold font-public-sans">
                    <Svgs.AddIconRed />
                    Add employees
                  </button>
                </div>
                <div className="w-full md:w-[160px]">
                  <SelectDropdown options={optionsRole} selectClass={' w-full'} customWidth={' w-full'} />
                </div>
              </div>
              <div className="flex items-center justify-center mt-5">
                <Button

                  handleClick={() => {
                    setShowSuccessTask(true);
                    setShowEditGroceryAttachment(false);
                  }}

                  text={"Create task"}
                  customPadding={"px-[24px] py-[14px]"}
                  className={"h-[48px] w-full md:w-[162px]"}
                />

              </div>
            </div>
          </div>
        </div>
      </PopupModal>
    
      <SuccessfulTask showSuccessTask={showSuccessTask} setShowSuccessTask={setShowSuccessTask} heading={'Link Copied'} />


    </>

  );
}

export default GroceryDialog;
